import React from "react";
import { ToastContainer, Toast } from "react-bootstrap";

import "../../styles/General/toasts.scss";

const ErrorToast = ({ errorMessage, isOpen, closeToast }) => {
  return (
    <ToastContainer position="bottom-end" className="errorToastCont">
      <Toast
        onClose={closeToast}
        show={isOpen}
        delay={3000}
        autohide
        bg="danger"
      >
        <Toast.Header closeVariant="white">
          <strong>Error</strong>
        </Toast.Header>
        <Toast.Body>
          <p>{errorMessage}</p>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ErrorToast;
