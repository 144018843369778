import React, { useState, useEffect } from "react";
import "../styles/skinComponents.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import DynamicValuesSkin from "./DynamicValuesSkin";
import SkinSelectionModalController from "./CalculatorModal/SkinSelectionModalController";

function SkinSelector({
  selectedSkin,
  skinSelectorIdx,
  currentRarity,
  isStatTrak,
  removeSelectedSkin,
  updateSelectedSkin,
  duplicateSelectedSkin,
}) {
  const [isSkinSelected, setIsSkinSelected] = useState(false);
  const [selectedSkinDetails, setSelectedSkinDetails] = useState({});
  const [showSkinSelector, setShowSkinSelector] = useState(false);

  // Modal Controls
  const openSkinSelectionComp = () => setShowSkinSelector(true);
  const closeSkinSelectionComp = () => setShowSkinSelector(false);

  useEffect(() => {
    if (selectedSkin !== null) {
      setIsSkinSelected(true);
      setSelectedSkinDetails(selectedSkin);
    } else {
      setIsSkinSelected(false);
    }
  }, [selectedSkin]);

  const handleSkinSelected = (details) => {
    setSelectedSkinDetails(details);
    setIsSkinSelected(true);
    // update skin in parent using current idx of skin. This avoids id clashes on copied skins
    updateSelectedSkin(skinSelectorIdx, details);
    closeSkinSelectionComp();
  };

  const floatValUpdated = (updatedFloatVal) => {
    const selectedSkinToUpdate = { ...selectedSkinDetails };
    selectedSkinToUpdate.floatVal = parseFloat(updatedFloatVal);
    setSelectedSkinDetails(selectedSkinToUpdate);

    handleSkinSelected(selectedSkinToUpdate);
  };

  const deselectSkin = () => {
    removeSelectedSkin(skinSelectorIdx);
    setIsSkinSelected(false);
  };

  return (
    <>
      <div className="selectorEl">
        {isSkinSelected ? (
          <>
            <div className="skinSelectedEditMenu">
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => deselectSkin()}
              />
              <FontAwesomeIcon
                icon={faCopy}
                onClick={() =>
                  duplicateSelectedSkin(skinSelectorIdx, selectedSkinDetails)
                }
              />
            </div>
            <div className="skinSelectedCont skinImgCard">
              <DynamicValuesSkin
                skinDetails={selectedSkinDetails}
                floatValUpdated={floatValUpdated}
                isStatTrak={isStatTrak}
                currentFloatVal={selectedSkinDetails.floatVal}
              />
            </div>
          </>
        ) : (
          <div
            className="selectSkinCont skinImgCard"
            onClick={openSkinSelectionComp}
          >
            <h1 className="selectSkinIcon">+</h1>
          </div>
        )}
      </div>
      <SkinSelectionModalController
        showSkinSelector={showSkinSelector}
        closeModal={closeSkinSelectionComp}
        currentRarity={currentRarity}
        isStatTrak={isStatTrak}
        handleSkinSelected={handleSkinSelected}
        currentSkinDetails={selectedSkinDetails}
      />
    </>
  );
}

export default SkinSelector;
