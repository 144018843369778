import React, { useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faCircleCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import OutcomesSkin from "./OutcomesSkin";
import ErrorToast from "./General/ErrorToast";
import "../styles/tradeUp.scss";

const TradeSummary = ({
  trade,
  isShowDelete = false,
  deleteTradeFromList,
  isAllowSave = true,
}) => {
  const {
    id,
    avgFloat,
    tradeupCost,
    profitability,
    profit,
    oddsToProfit,
    inputSkins,
    outputSkins,
  } = trade;

  const [isSaved, setIsSaved] = useState(false);
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);

  const saveGeneratedTrade = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/saveGeneratedTradeData`, {
        id,
      })
      .then(() => {
        setIsSaved(true);
      })
      .catch((e) => {
        console.log("Error saving generated trade", e);
        setIsShowErrorToast(true);
      });
  };

  const renderTradeSkins = (skinArr) => {
    if (skinArr?.length === 0) return <></>;

    return skinArr.map((skin, i) => {
      return (
        <div className="tradeSummarySkin" key={i}>
          <OutcomesSkin skinDetails={skin} />
        </div>
      );
    });
  };

  const oddsToProfitPercentage = parseFloat(oddsToProfit) * 100;

  const fixedAvgFloat = parseFloat(avgFloat).toFixed(7);
  const fixedtradeupCost = `$${parseFloat(tradeupCost).toFixed(2)}`;
  const fixedProfitability = `${parseFloat(profitability).toFixed(2)}%`;
  const fixedProfit = `$${parseFloat(profit).toFixed(2)}`;
  const fixedOddsToProfit = `${parseFloat(oddsToProfitPercentage).toFixed(2)}%`;

  return (
    <>
      <Accordion.Item className="tradeSummaryCont" eventKey={id}>
        <Accordion.Header className="tradeSummaryHeader" as="div">
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">AVERAGE FLOAT</p>
            <p>{fixedAvgFloat}</p>
          </div>
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">TRADE UP COST</p>
            <p>{fixedtradeupCost}</p>
          </div>
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">PROFITABLITY</p>
            <p>{fixedProfitability}</p>
          </div>
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">PROFIT</p>
            <p>{fixedProfit}</p>
          </div>
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">ODDS TO PROFIT</p>
            <p>{fixedOddsToProfit}</p>
          </div>
          {isShowDelete && (
            <div className="tradeSummaryHeaderItem tradeSummaryHeaderDeleteIcon">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => deleteTradeFromList(id)}
              />
            </div>
          )}
        </Accordion.Header>
        <Accordion.Body className="tradeSummaryAllTrades">
          <div className="tradeSkinsCont">
            <h3 className="tradeSkinsTitle">INPUTS</h3>
            <div className="tradeSkinsList">
              {renderTradeSkins(inputSkins, false)}
            </div>
          </div>
          <div className="tradeSkinsCont">
            <h3 className="tradeSkinsTitle tradesSkinOutcomesTitle">
              OUTCOMES
            </h3>
            <div className="tradeSkinsList">
              {renderTradeSkins(outputSkins, true)}
            </div>

            {isAllowSave && (
              <Button
                variant="secondary"
                className="tradeCollectionSelectionBtn"
              >
                {isSaved ? (
                  <span>
                    Saved{" "}
                    <FontAwesomeIcon
                      className="savedButtonIcon"
                      icon={faCircleCheck}
                    />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      saveGeneratedTrade();
                    }}
                  >
                    Save{" "}
                    <FontAwesomeIcon
                      className="savedButtonIcon"
                      icon={faFloppyDisk}
                    />
                  </span>
                )}
              </Button>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <ErrorToast
        errorMessage={"Error saving trade to profile"}
        isOpen={isShowErrorToast}
        closeToast={() => setIsShowErrorToast(false)}
      />
    </>
  );
};

export default TradeSummary;
