import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import SkinSelectionModal from "./SkinSelectionModal";
import SkinFocusedModal from "./SkinFocusedModal";

import "../../styles/selectionModal.scss";

const SkinSelectionModalController = ({
  showSkinSelector,
  currentRarity,
  isStatTrak,
  closeModal,
  handleSkinSelected,
  currentSkinDetails,
}) => {
  const [isSkinFocused, setIsSkinFocused] = useState(false);
  const [selectedSkinDetails, setSelectedSkinDetails] =
    useState(currentSkinDetails);

  const handleSkinFocused = (skinDetails) => {
    setSelectedSkinDetails(skinDetails);
    setIsSkinFocused(true);
  };

  // If a skin in focused and the close button is clicked return to the skin list view
  const handleOnClose = () => {
    if (isSkinFocused) {
      return setIsSkinFocused(false);
    }
    closeModal();
  };

  const renderAllSkinsList = () => {
    return (
      <SkinSelectionModal
        currentRarity={currentRarity}
        isStatTrak={isStatTrak}
        handleSkinFocused={handleSkinFocused}
      />
    );
  };

  const renderFocusedSkin = () => {
    return (
      <SkinFocusedModal
        selectedSkinDetails={selectedSkinDetails}
        handleSkinSelected={handleSkinSelected}
        currentRarity={currentRarity}
        isStatTrak={isStatTrak}
        triggerOnClose={handleOnClose}
      />
    );
  };

  //* Modal contains default loading state that displays on intiial list load
  /*//* In the case a skin is focused, transition the internal state of the modal.
        The beginning state of the ternary: !isSkinFocused represents the default state
  */
  return (
    <Modal
      className="modalCont"
      show={showSkinSelector}
      onHide={handleOnClose}
      size="xl"
      centered
    >
      <div
        className={`modalContentCont ${
          isSkinFocused ? "showSkinFocused" : "showSkinList"
        }`}
      >
        {isSkinFocused ? renderFocusedSkin() : renderAllSkinsList()}
      </div>
    </Modal>
  );
};

export default SkinSelectionModalController;
