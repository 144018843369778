import React from "react";

import "../../styles/home.scss";

const TermsOfService = () => {
  return (
    <div className="legalCont">
      <h1 className="legalHeaderCentered">Terms of Use for TradeUpFinder</h1>

      <p className="legalHeaderCentered">Effective Date: [06.07.2024]</p>

      <h3>1. Introduction</h3>

      <p>
        Welcome to TradeUpFinder, operated by SP SOFTWARE SOLUTIONS OÜ ("we,"
        "us," or "our"). These Terms of Use ("Terms") govern your access to and
        use of our services, including our website, applications, and tools
        (collectively, the "Service"). By accessing or using our Service, you
        agree to comply with and be bound by these Terms.
      </p>

      <h3>2. Company Information</h3>

      <p>
        <strong>Name</strong>: SP SOFTWARE SOLUTIONS OÜ
      </p>
      <p>
        <strong>Address</strong>: Harju maakond, Tallinn, Kesklinna linnaosa, A.
        Reinvaldi tn 3-2, 10121, Estonia
      </p>

      <h3>3. Eligibility</h3>

      <p>
        You must be at least 18 years old to use our Service. If you are under
        18, you may only use the Service with the consent and supervision of a
        parent or legal guardian.
      </p>

      <h3>4. Account Registration</h3>

      <p>
        To access certain features of our Service, you may need to create an
        account. When you create an account, you agree to:
      </p>

      <ul>
        <li>Provide accurate, current, and complete information</li>
        <li>Maintain and promptly update your account information</li>
        <li>Keep your password confidential and secure</li>
        <li>Notify us immediately of any unauthorized use of your account</li>
      </ul>

      <p>
        You are responsible for all activities that occur under your account.
      </p>

      <h3>5. Use of the Service</h3>

      <p>
        You agree to use the Service in compliance with these Terms and all
        applicable laws and regulations. You agree not to:
      </p>

      <ul>
        <li>Use the Service for any unlawful or fraudulent purpose</li>
        <li>Interfere with or disrupt the Service or servers</li>
        <li>
          Attempt to gain unauthorized access to any part of the Service or its
          related systems or networks
        </li>
        <li>
          Use any automated means (such as bots or scrapers) to access the
          Service
        </li>
      </ul>

      <h3>6. Intellectual Property</h3>

      <p>
        All content, features, and functionality of the Service, including but
        not limited to text, graphics, logos, and software, are the exclusive
        property of SP SOFTWARE SOLUTIONS OÜ and are protected by international
        copyright, trademark, patent, trade secret, and other intellectual
        property laws.
      </p>

      <h3>7. User Content</h3>

      <p>
        You may submit, post, or display content (such as feedback and comments)
        through the Service ("User Content"). By submitting User Content, you
        grant us a non-exclusive, worldwide, royalty-free, perpetual, and
        sublicensable right to use, modify, reproduce, and distribute your User
        Content in connection with the Service.
      </p>

      <p>
        You represent and warrant that you own or have the necessary rights to
        your User Content and that your User Content does not infringe the
        rights of any third party.
      </p>

      <h3>8. Payment and Subscriptions</h3>

      <p>
        Certain features of the Service may require payment. If you choose to
        purchase a subscription or make a payment, you agree to provide accurate
        and complete payment information. Payments are processed through Stripe,
        our third-party payment processor.
      </p>

      <p>
        All fees are non-refundable except as required by law or as provided in
        these Terms.
      </p>

      <h3>9. Termination</h3>

      <p>
        We reserve the right to suspend or terminate your account or access to
        the Service at our sole discretion, without notice, for conduct that we
        believe violates these Terms or is harmful to other users or the
        Service.
      </p>

      <h3>10. Disclaimers and Limitation of Liability</h3>

      <p>
        The Service is provided on an "as-is" and "as-available" basis. We make
        no warranties, express or implied, regarding the Service, including but
        not limited to its accuracy, reliability, or availability.
      </p>

      <p>
        To the fullest extent permitted by law, SP SOFTWARE SOLUTIONS OÜ, its
        affiliates, and its licensors are not liable for any indirect,
        incidental, special, consequential, or punitive damages, or any loss of
        profits or revenues, whether incurred directly or indirectly, or any
        loss of data, use, goodwill, or other intangible losses resulting from:
      </p>

      <ul>
        <li>Your use of or inability to use the Service</li>
        <li>
          Any unauthorized access to or use of our servers and/or any personal
          information stored therein
        </li>
        <li>
          Any interruption or cessation of transmission to or from the Service
        </li>
        <li>
          Any bugs, viruses, trojans, or the like that may be transmitted to or
          through our Service by any third party
        </li>
      </ul>

      <h3>11. Indemnification</h3>

      <p>
        You agree to indemnify, defend, and hold harmless SP SOFTWARE SOLUTIONS
        OÜ, its affiliates, and their respective officers, directors, employees,
        agents, licensors, and suppliers from and against any claims,
        liabilities, damages, judgments, awards, losses, costs, expenses, or
        fees (including reasonable attorneys' fees) arising out of or relating
        to your violation of these Terms or your use of the Service.
      </p>

      <h3>12. Governing Law and Dispute Resolution</h3>

      <p>
        These Terms are governed by and construed in accordance with the laws of
        Estonia, without regard to its conflict of law principles. Any disputes
        arising under or in connection with these Terms shall be resolved
        through binding arbitration in Estonia, except that either party may
        seek injunctive or other equitable relief in any court of competent
        jurisdiction.
      </p>

      <h3>13. Changes to the Terms</h3>

      <p>
        We may update these Terms from time to time. We will notify you of any
        changes by posting the new Terms on our website. You are advised to
        review these Terms periodically for any changes. Your continued use of
        the Service after the posting of changes constitutes your acceptance of
        such changes.
      </p>

      <h3>14. Contact Information</h3>

      <p>
        If you have any questions or concerns about these Terms or the Service,
        please contact us at info@tradeupfinder.com.
      </p>

      <h3>15. Miscellaneous</h3>

      <p>
        These Terms constitute the entire agreement between you and SP SOFTWARE
        SOLUTIONS OÜ regarding the use of the Service. If any provision of these
        Terms is found to be invalid or unenforceable, the remaining provisions
        will continue in full force and effect. Our failure to enforce any right
        or provision of these Terms will not be considered a waiver of those
        rights.
      </p>

      <hr />

      <p>
        By using TradeUpFinder, you acknowledge that you have read, understood,
        and agree to be bound by these Terms of Use.
      </p>
    </div>
  );
};

export default TermsOfService;
