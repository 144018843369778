import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";

import OutcomesSkin from "../OutcomesSkin";
import DynamicValuesSkin from "../DynamicValuesSkin";

const SkinFocusedModal = ({
  selectedSkinDetails,
  handleSkinSelected,
  currentRarity,
  isStatTrak,
  triggerOnClose,
}) => {
  const { name, collection } = selectedSkinDetails;

  const [isOutcomesLoading, setIsOutcomesLoading] = useState(true);
  const [outcomeSkins, setOutcomeSkins] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/singleSkinPotentialOutcomes?id=${selectedSkinDetails.id}`
      )
      .then((res) => {
        setOutcomeSkins(res.data);
      })
      .then(() => setIsOutcomesLoading(false))
      .catch((e) => {
        console.log("error fetching possible outcomes for focused skin", e);
      });
  }, []);

  const renderPotentialOutcomeSkins = () => {
    return outcomeSkins.map((skinDetails, i) => {
      return (
        <OutcomesSkin key={i} skinDetails={skinDetails} isHasFloat={false} />
      );
    });
  };

  return (
    <>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          <span className="skinDetailTitle">{`${name} - `}</span>
          <span className="skinDetailCollection">{collection}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="skinFocusedDetails" md={4}>
            <div className="selectedSkinBorder">
              <DynamicValuesSkin
                skinDetails={selectedSkinDetails}
                currentRarity={currentRarity}
                isStatTrak={isStatTrak}
              />
            </div>
            <Button
              className="skinFocusedAddSkinBtn"
              variant="outline-primary"
              onClick={() => handleSkinSelected(selectedSkinDetails)}
            >
              Add to calculator
            </Button>
            <Button
              className="skinFocusedAddSkinBtn"
              variant="outline-primary"
              onClick={() => triggerOnClose()}
            >
              Back to list
            </Button>
          </Col>
          <Col className="selectedSkinOutcomesCol" md={8}>
            <h2 className="selectedSkinOutcomesTitle">Potential outcomes</h2>
            {isOutcomesLoading ? (
              <div className="loadingCont">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="selectedSkinPotentialOutcomes">
                {renderPotentialOutcomeSkins()}
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export default SkinFocusedModal;
