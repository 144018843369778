import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import Spinner from "react-bootstrap/Spinner";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import "../../styles/subscribe.scss";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_LIVE_PK}`);

const Payments = () => {
  const [stripeOptions, setStripeOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { selectedPlan } = useParams();

  useEffect(() => {
    let planPriceId = "";
    switch (selectedPlan) {
      case "1mo":
        planPriceId = "price_1PYTOvG7aAxkgKRvXu6lRkwH";
        break;
      case "3mo":
        planPriceId = "price_1PZY5HG7aAxkgKRvbBC9p91S";
        break;
      case "1yr":
        planPriceId = "price_1PZY63G7aAxkgKRvfcW9Tp2r";
        break;
      default:
        console.log("Plan not found");
        break;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/create_checkout_session`, {
        price: planPriceId,
      })
      .then((res) => {
        setStripeOptions({ clientSecret: res.data });
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("Error fetching payment intent", e);
      });
  }, [selectedPlan]);

  return (
    <div className="checkoutCont">
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={stripeOptions}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default Payments;

/* Testing
case "1mo":
  planPriceId = "price_1PbRULG7aAxkgKRvUYogkXNQ";
  break;
case "3mo":
  planPriceId = "price_1PbRuNG7aAxkgKRvr3vKxm6w";
  break;

*/
