import React, { useState, useEffect } from "react";
import axios from "axios";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ConditionScale from "./ConditionScale";
import ErrorToast from "./General/ErrorToast";

import "../styles/skinComponents.scss";

function DynamicValuesSkin({
  skinDetails,
  currentRarity,
  isStatTrak,
  propsFloatVal,
}) {
  const {
    id,
    image_url,
    name,
    collection,
    floatVal,
    price,
    float_range_min,
    float_range_max,
  } = skinDetails;
  const skinFloatValues = {
    minFloatValue: float_range_min,
    maxFloatValue: float_range_max,
  };

  const [currentFloatVal, setCurrentFloatVal] = useState(floatVal);
  const [floatWDecimalLimit, setFloatWDecimalLimit] = useState(
    floatVal.toFixed(5)
  );

  // Currently receiving value 0 as string from server
  const [currentPrice, setCurrentPrice] = useState(
    price === "0" ? "No recent price" : `$${price}`
  );

  const [isShowError, setIsShowError] = useState(false);

  useEffect(() => {
    //update price on float val change
    if (floatVal !== currentFloatVal) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/priceFromFloat?id=${id}&floatVal=${currentFloatVal}&isStatTrak=${isStatTrak}`
        )
        .then((res) => {
          setCurrentPrice(`$${res.data.price}`);
        })
        .catch((e) => console.log("error fetching price from floatVal", e));
    }
  }, [id, floatVal, currentFloatVal, currentRarity, isStatTrak]);

  useEffect(() => {
    // force state change in the case the float value is updated in the skin focused modal
    if (propsFloatVal) {
      setCurrentFloatVal(propsFloatVal);
      setFloatWDecimalLimit(propsFloatVal);
    }
  }, [propsFloatVal]);

  const updateCurrentFloatVal = (currentFloatValArg) => {
    // protection for ineffective float val change
    // e.g. adding a 0 at the end of the value or outside of min/max value
    if (currentFloatValArg !== currentFloatVal) {
      if (
        currentFloatValArg < float_range_max &&
        currentFloatValArg > float_range_min
      ) {
        setCurrentFloatVal(currentFloatValArg);
        setFloatWDecimalLimit(currentFloatValArg);
      } else {
        setIsShowError(true);
      }
    }
  };

  const handlePrice = (unhandledPrice) => {
    // strip out any dollar signs or invalid characters and ensure only one decimal
    let displayPrice = parseFloat(
      unhandledPrice.replace(/[^0-9.]|(\.(?=.*\.))/g, "")
    );

    if (displayPrice === 0) {
      return setCurrentPrice("No recent price");
    }

    setCurrentPrice(`$${parseFloat(displayPrice).toFixed(2)}`);
  };

  return (
    <div className="skinCont skinImgCard">
      <div className="skinCardHeader">
        <OverlayTrigger overlay={<Tooltip>{collection}</Tooltip>}>
          <p className="skinCardHeaderCollection">{collection}</p>
        </OverlayTrigger>
        <input
          className="skinCardFloatInput"
          value={floatWDecimalLimit}
          onChange={(e) =>
            updateCurrentFloatVal(
              parseFloat(e.target.value.replace(/[^0-9.]|(\.(?=.*\.))/g, ""))
            )
          }
        />
      </div>
      <img className="skinCardImg" src={image_url} alt={name} />
      <p className="skinCardTitle">{name}</p>
      <div className="skinCardConditionScale">
        <ConditionScale
          floatVal={currentFloatVal}
          skinFloatValues={skinFloatValues}
          updateCondition={updateCurrentFloatVal}
        />
      </div>
      <div className="skinSelectedValues">
        <input
          className="skinCardPriceInput"
          value={currentPrice}
          onChange={(e) => handlePrice(e.target.value)}
        />
      </div>
      <ErrorToast
        errorMessage={"Float value is outside of skin range"}
        isOpen={isShowError}
        closeToast={() => setIsShowError(false)}
      />
    </div>
  );
}

export default DynamicValuesSkin;
