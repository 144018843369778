import React, { useState } from "react";
import { Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";

import {
  ALL_COLLECTIONS,
  ALL_STATTRAK_COLLECTIONS,
} from "../utils/constants.utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";

const CollectionDropdown = ({
  selectedCollection,
  updateSelectedCollection,
  isStatTrak,
  isDisabled = false,
  isShowReset = false,
}) => {
  const [searchCollectionVal, setSearchCollectionVal] = useState("");
  const [filteredCollections, setFilteredCollections] =
    useState(ALL_COLLECTIONS);

  const handleCollectionChange = (e) => {
    const collectionInput = e.target.value;
    setSearchCollectionVal(collectionInput);

    const collectionsList = isStatTrak
      ? ALL_STATTRAK_COLLECTIONS
      : ALL_COLLECTIONS;
    setFilteredCollections(
      collectionsList.filter((collectionName) =>
        collectionName.toLowerCase().includes(collectionInput.toLowerCase())
      )
    );
  };

  const currentlyFilteredCollection = () => {
    const currentColumns = filteredCollections.map((collectionName) => {
      return (
        <Dropdown.Item
          key={collectionName}
          eventKey={collectionName}
          className="dropdownItem"
          active={selectedCollection === collectionName}
        >
          {collectionName}
        </Dropdown.Item>
      );
    });

    if (isShowReset && selectedCollection !== "Select collection") {
      currentColumns.unshift(
        <Dropdown.Item
          key={"Clear collection"}
          eventKey={"Clear"}
          className="dropdownItem"
        >
          Clear Selection <FontAwesomeIcon icon={faEraser} />
        </Dropdown.Item>
      );
    }

    return currentColumns;
  };

  return (
    <DropdownButton
      as={ButtonGroup}
      onSelect={(eventKey) => {
        updateSelectedCollection(eventKey);
      }}
      variant="outline-primary"
      title={selectedCollection}
      className="collectionsDropdownCont"
      disabled={isDisabled}
    >
      <Dropdown.Header>
        <input
          type="text"
          className="form-control dropdownSearchField"
          placeholder="Search collections"
          value={searchCollectionVal}
          onChange={handleCollectionChange}
        />
      </Dropdown.Header>
      {currentlyFilteredCollection()}
    </DropdownButton>
  );
};

export default CollectionDropdown;
