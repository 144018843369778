import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Col,
  Row,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import TradeSummary from "../../components/TradeSummary";

import { ALL_COLLECTIONS } from "../../utils/constants.utils";
import {
  RENDER_HOWITWORKS_SECTION,
  RENDER_BENEFITS_SECTION,
  RENDER_FAQ_SECTION,
} from "../../utils/AboutSections.utils";

import "../../styles/tradeUp.scss";
import "../../styles/home.scss";

const TradeupPreview = ({ sampleData }) => {
  const { inputSkins } = sampleData;
  const defaultCollectionState = "Select a collection";
  const navigate = useNavigate();

  const [collectionsSelected, setCollectionsSelected] = useState({
    1: "",
    2: defaultCollectionState,
  });

  useEffect(() => {
    const firstCollection = inputSkins[0].collection;
    setCollectionsSelected((prevCollections) => {
      return { ...prevCollections, 1: firstCollection };
    });

    inputSkins.forEach((inputSkin) => {
      if (inputSkin.collection !== firstCollection) {
        setCollectionsSelected((prevCollections) => {
          return { ...prevCollections, 2: inputSkin.collection };
        });
      }
    });
  }, [inputSkins]);

  return (
    <div className="tradeUpCont tradeUpPreviewCont">
      <Row className="tradeUpTitleCont">
        <Col md={{ span: 9, offset: 1 }}>
          <h1>
            ACCESS UNLIMITED PROFITABLE TRADE UPS AND DISCOVER THEM BEFORE THE
            MARKET AND START EARNING REAL PROFIT
          </h1>
          <Button
            variant="secondary"
            className="tradeCollectionSelectionBtn"
            onClick={() => navigate("/subscribe")}
          >
            Subscribe to Pro
          </Button>
        </Col>
      </Row>
      <Row className="tradeSelectionCont" md={12}>
        <Col md="10" className="tradeSummariesColumnCont">
          <div className="tradeCollectionSelectors">
            <div className="tradeCollectionSelectorCont">
              <h2 className="tradeDropdownSelectorsLabel">
                TRADE UP YOUR PLAY SKINS AND EARN REAL CASH PROFITS USING THE
                MOST ADVANCED AI SUPPORTED TRADE UP GENERATOR
              </h2>
              <h3 className="tradeDropdownSelectorsLabel">
                Unlock all collections with Pro
              </h3>
              <div className="tradeCollectionSelectorDropdowns">
                <DropdownButton
                  as={ButtonGroup}
                  variant="outline-primary"
                  title={collectionsSelected[1]}
                  className="collectionsDropdownCont"
                >
                  {ALL_COLLECTIONS.map((collectionName) => {
                    return (
                      <Dropdown.Item
                        key={collectionName}
                        eventKey={collectionName}
                        className="dropdownItem"
                        active={collectionsSelected[1] === collectionName}
                        disabled
                      >
                        {collectionName}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <OverlayTrigger
                  overlay={<Tooltip>Unlock all collections with Pro</Tooltip>}
                >
                  <DropdownButton
                    as={ButtonGroup}
                    variant="outline-primary"
                    title={collectionsSelected[2]}
                    className="collectionsDropdownCont"
                    disabled={collectionsSelected[2] === defaultCollectionState}
                  ></DropdownButton>
                </OverlayTrigger>
                <Button
                  variant="secondary"
                  className="tradeCollectionSelectionBtn"
                >
                  Generate trades
                </Button>
              </div>
            </div>
          </div>
          <Accordion
            className="tuAccordionWrapper tradeCollectionAccordion tradeCollectionAccordionSample"
            defaultActiveKey={null}
          >
            <TradeSummary trade={sampleData} isAllowSave={false} />
          </Accordion>
        </Col>
      </Row>
      <div className="tradePreviewSubscribeBtnCont">
        <Button
          variant="secondary"
          className="tradePreviewSubscribeBtn"
          onClick={() => navigate("/subscribe")}
          size="lg"
        >
          Subscribe to Pro
        </Button>
      </div>
      <div className="homepageCont">
        {RENDER_HOWITWORKS_SECTION()}
        {RENDER_BENEFITS_SECTION()}
        <div className="tradePreviewSubscribeBtnCont">
          <Button
            variant="secondary"
            className="tradePreviewSubscribeBtn"
            onClick={() => navigate("/subscribe")}
            size="lg"
          >
            Subscribe to Pro
          </Button>
        </div>
        {RENDER_FAQ_SECTION()}
      </div>
    </div>
  );
};

export default TradeupPreview;
