import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

import "../../styles/subscribe.scss";
import { Button } from "react-bootstrap";

const CheckoutSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/session_status?session_id=${sessionId}`
      )
      .then((res) => {
        const paymentStatus = res.data.status;
        if (paymentStatus === "open") {
          navigate("/checkout");
        }
      });
  }, [navigate]);

  return (
    <div className="checkoutSuccessCont">
      <h1>You're subscribed! You can start trading now</h1>
      <Button
        variant="secondary"
        className="checkoutSuccessBtn"
        onClick={() => navigate("/trade-up")}
      >
        Start trading!
      </Button>
    </div>
  );
};

export default CheckoutSuccess;
