import React, { useState, useEffect } from "react";
import axios from "axios";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import HeaderAuthBtn from "../HeaderAuthBtn";
import { isUserLoggedIn } from "../../utils/General.utils";

import "../../styles/General/header.scss";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isUserSubscribed, setIsUserSubscribed] = useState(false);

  useEffect(() => {
    if (isUserLoggedIn()) {
      return setIsUserSubscribed(false);
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/isUserSubscribed`)
      .then((res) => {
        const isSubscribed = res.data.subscribed;
        setIsUserSubscribed(isSubscribed);
      })
      .catch((e) => {
        console.log("Error fetching subscription data", e);
      });
  }, [location]);

  return (
    <Navbar className="navBarCont" fixed="top" expand="lg">
      <Navbar.Brand onClick={() => navigate("/")}>
        <img
          src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/TradeUpFinder-LogoTextFlat.png"
          alt="Tradeup Finder logo"
          height="70"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="menuLinks">
        <Nav>
          <LinkContainer to="/calculator">
            <Nav.Link>Calculator</Nav.Link>
          </LinkContainer>
          <div className="proTextCont">
            <LinkContainer to="/trade-up">
              <Nav.Link>Trade up</Nav.Link>
            </LinkContainer>
            <p className="proText">Pro</p>
          </div>
          {isUserSubscribed ? (
            <></>
          ) : (
            <LinkContainer to="/subscribe">
              <Nav.Link>Subscribe</Nav.Link>
            </LinkContainer>
          )}
          <LinkContainer to="/login">
            <HeaderAuthBtn />
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
