import React, { useState, useEffect } from "react";
import { ButtonGroup, Button } from "react-bootstrap";

import { CONDITION_SCALE_RANGES } from "../utils/constants.utils";
import {
  calculateLinePosition,
  ConditionLabels,
} from "../utils/ConditionScale.utils";

import "../styles/conditionScale.scss";

function StaticConditionScale({ floatVal, skinFloatValues, isHasFloat }) {
  const { maxFloatValue, minFloatValue } = skinFloatValues;
  // adjust skin values for display
  const fixedFloatVal = (Math.trunc(floatVal * 1000) / 1000).toFixed(3); //float val beneath scale, truncate at 3rd decimal without rounding

  const [currentCondition, setCurrentCondition] = useState("");
  const [floatValPosition, setFloatValPosition] = useState("");

  useEffect(() => {
    // set initial condition based on the float value
    if (isHasFloat) {
      for (const [condition, floatBounds] of Object.entries(
        CONDITION_SCALE_RANGES
      )) {
        const min = floatBounds.min,
          max = floatBounds.max;
        if (floatVal >= min && floatVal <= max) {
          setCurrentCondition(condition);
          break;
        }
      }
      const initialFloatValPosition = calculateLinePosition(floatVal);
      setFloatValPosition(initialFloatValPosition);
    } else {
      // in case of skin with no value do not set a condition
      setCurrentCondition(null);
    }
  }, [floatVal, isHasFloat]);

  return (
    <div className="conditionScaleCont staticConditionScale">
      <div className="scaleConditions">
        <div className="rangeText">
          <div
            className="minMaxlabelStyle maxLabelColor"
            style={{ left: calculateLinePosition(minFloatValue) }}
          >
            min
          </div>
          <div
            className="minMaxlabelStyle minLabel"
            style={{ left: calculateLinePosition(maxFloatValue) }}
          >
            max
          </div>
        </div>
        <ButtonGroup className="scaleConditionsBtnGroup">
          <Button
            className={`scaleSection ${
              currentCondition === "FN" ? "selectedConditionBg" : ""
            }`}
          >
            FN
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "MW" ? "selectedConditionBg" : ""
            }`}
          >
            MW
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "FT" ? "selectedConditionBg" : ""
            }`}
          >
            FT
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "WW" ? "selectedConditionBg" : ""
            }`}
          >
            WW
          </Button>
          {/* place lines before final button to ensure button group border radius is properly applied */}
          <div
            className="conditionScaleLine minLine"
            style={{ left: calculateLinePosition(minFloatValue) }}
          ></div>
          {isHasFloat && (
            <div
              className="conditionScaleLine currentValLine"
              style={{ left: floatValPosition }}
            ></div>
          )}
          <div
            className="conditionScaleLine maxLine"
            style={{ left: calculateLinePosition(maxFloatValue) }}
          ></div>
          <Button
            className={`scaleSection ${
              currentCondition === "BS" ? "selectedConditionBg" : ""
            }`}
          >
            BS
          </Button>
        </ButtonGroup>

        <div className="rangeText">
          <div className="valueLabels">
            <span
              className="valueLabelStyle maxLabelColor"
              style={{ left: calculateLinePosition(minFloatValue) }}
            >
              <span>{minFloatValue}</span>
            </span>
            <span
              className="valueLabelStyle minLabelColor minValueLabel"
              style={{ left: calculateLinePosition(maxFloatValue) }}
            >
              <span>{maxFloatValue}</span>
            </span>
            {isHasFloat && (
              <span
                className="valueLabelStyle currentValLabel"
                style={{ left: floatValPosition }}
              >
                {fixedFloatVal}
              </span>
            )}
          </div>
          <ConditionLabels />
        </div>
      </div>
    </div>
  );
}

export default StaticConditionScale;
