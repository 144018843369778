import React from "react";

import "../../styles/home.scss";

const PrivacyPolicy = () => {
  return (
    <div className="legalCont">
      <h1 className="legalHeaderCentered">Privacy Policy for TradeUpFinder</h1>

      <p className="legalHeaderCentered">Effective Date: [06.07.2024]</p>

      <h3>1. Introduction</h3>

      <p>
        Welcome to TradeUpFinder, operated by SP SOFTWARE SOLUTIONS OÜ ("we,"
        "us," or "our"). We are committed to protecting your privacy and
        ensuring the security of your personal information. This Privacy Policy
        outlines how we collect, use, disclose, and safeguard your information
        when you use our service.
      </p>

      <h3>2. Company Information</h3>

      <p>
        <strong>Name</strong>: SP SOFTWARE SOLUTIONS OÜ
      </p>
      <p>
        <strong>Address</strong>: Harju maakond, Tallinn, Kesklinna linnaosa, A.
        Reinvaldi tn 3-2, 10121, Estonia
      </p>

      <h3>3. Description of Services</h3>

      <p>
        TradeUpFinder helps users find profitable CS:GO trade-ups and provides a
        CS:GO skin trade-up calculator.
      </p>

      <h3>4. Target Audience</h3>

      <p>
        Our service is intended for individuals interested in CS:GO trading and
        analytics. Users under the age of 18 should have parental consent to use
        our services.
      </p>

      <h3>5. Data Collection</h3>

      <p>
        <strong>Personal Data</strong>:
      </p>

      <p>
        We collect personal information that you provide to us when you create
        an account, such as your email address. Payment information is collected
        and processed through our third-party payment processor, Stripe.
      </p>

      <p>
        <strong>Usage Data</strong>:
      </p>

      <p>
        We may collect information on how you interact with our service,
        including your IP address, browser type, usage logs, and other
        diagnostic data.
      </p>

      <p>
        <strong>Cookies and Tracking</strong>:
      </p>

      <p>
        We use cookies and similar tracking technologies to track the activity
        on our service and hold certain information. Cookies are files with a
        small amount of data that may include an anonymous unique identifier.
      </p>

      <h3>6. Purpose of Data Collection</h3>

      <p>We collect and use your personal data for the following purposes:</p>

      <ul>
        <li>To provide and maintain our service</li>
        <li>To manage your account and process transactions</li>
        <li>
          To communicate with you, including sending updates and promotional
          materials
        </li>
        <li>To improve our services through analysis and research</li>
        <li>To ensure compliance with legal obligations</li>
      </ul>

      <h3>7. Sharing Data</h3>

      <p>
        We do not sell or share your personal data with third parties except in
        the following circumstances:
      </p>

      <ul>
        <li>
          With service providers to facilitate our service (e.g., payment
          processors like Stripe)
        </li>
        <li>
          When required by law or to protect the rights and safety of our
          company and users
        </li>
        <li>In connection with a merger, sale, or other business transfer</li>
      </ul>

      <h3>8. User Rights</h3>

      <p>You have the right to:</p>

      <ul>
        <li>Access and obtain a copy of your data</li>
        <li>Rectify any inaccuracies in your data</li>
        <li>Request deletion of your data</li>
        <li>Object to or restrict the processing of your data</li>
      </ul>

      <p>
        To exercise these rights, please contact us at [Insert Contact Email].
      </p>

      <h3>9. Data Protection</h3>

      <p>
        We implement appropriate technical and organizational measures to
        protect your personal data from unauthorized access, use, or disclosure.
        These measures include encryption, access controls, and security audits.
      </p>

      <h3>10. Data Retention</h3>

      <p>
        We retain your personal data only for as long as necessary to fulfill
        the purposes for which it was collected or to comply with legal
        obligations. Once your data is no longer needed, we will delete or
        anonymize it.
      </p>

      <h3>11. Legal Bases for Processing</h3>

      <p>We process your personal data based on the following legal bases:</p>

      <ul>
        <li>Your consent</li>
        <li>The necessity to perform a contract with you</li>
        <li>
          Our legitimate interests in operating and improving our services
        </li>
        <li>Compliance with legal obligations</li>
      </ul>

      <h3>12. Cross-Border Data Transfers</h3>

      <p>
        If your data is transferred to countries outside the European Economic
        Area, we ensure adequate protection through mechanisms such as standard
        contractual clauses or other legal safeguards.
      </p>

      <h3>13. Policy Updates</h3>

      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated effective date. We encourage you to
        review this policy periodically.
      </p>

      <h3>14. Contact Information</h3>

      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at [info@tradeupfinder.com].
      </p>

      <h3>15. Minors</h3>

      <p>
        We do not knowingly collect personal data from minors under 18 without
        parental consent. If we learn that we have collected data from a minor
        without such consent, we will delete it promptly.
      </p>

      <h3>16. Third-Party Links</h3>

      <p>
        Our service may contain links to third-party websites. We are not
        responsible for the privacy practices or content of these sites. We
        encourage you to review the privacy policies of any third-party sites
        you visit.
      </p>

      <hr />

      <p>
        By using TradeUpFinder, you agree to this Privacy Policy and consent to
        the processing of your personal data as described herein.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
