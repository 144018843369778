import axios from "axios";

export const isUserLoggedIn = () => {
  if (localStorage.getItem("authToken") === null) {
    return false;
  }
  return true;
};

export const isUserSubscribed = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/isUserSubscribed`
    );
    return res.data.subscribed;
  } catch (e) {
    return false;
  }
};
