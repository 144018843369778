export const RARITIES = {
  CG: "Consumer",
  IG: "Industrial",
  MS: "Mil-Spec",
  R: "Restricted",
  C: "Classified",
};

export const CONDITION_SCALE_RANGES = {
  FN: { min: 0.001, max: 0.07 },
  MW: { min: 0.07, max: 0.15 },
  FT: { min: 0.15, max: 0.38 },
  WW: { min: 0.38, max: 0.45 },
  BS: { min: 0.45, max: 1 },
};

export const ALL_COLLECTIONS = [
  "Operation Hydra Case",
  "Revolver Case",
  "Chroma Case",
  "Operation Breakout Weapon Case",
  "The Office Collection",
  "Revolution Case",
  "Winter Offensive Weapon Case",
  "eSports 2013 Winter Case",
  "eSports 2014 Summer Case",
  "X-Ray P250 Package",
  "The Alpha Collection",
  "Operation Broken Fang Case",
  "eSports 2013 Case",
  "CS20 Case",
  "The Militia Collection",
  "The 2018 Nuke Collection",
  "CS:GO Weapon Case 3",
  "The Safehouse Collection",
  "The 2021 Vertigo Collection",
  "The Cache Collection",
  "CS:GO Weapon Case",
  "The 2018 Inferno Collection",
  "The 2021 Dust 2 Collection",
  "Glove Case",
  "Gamma 2 Case",
  "Operation Wildfire Case",
  "Operation Bravo Case",
  "The Nuke Collection",
  "The Gods and Monsters Collection",
  "The Rising Sun Collection",
  "The Huntsman Collection",
  "The St. Marc Collection",
  "Spectrum Case",
  "The Havoc Collection",
  "The Norse Collection",
  "Dreams & Nightmares Case",
  "The Ancient Collection",
  "Fracture Case",
  "The Aztec Collection",
  "Shattered Web Case",
  "Spectrum 2 Case",
  "The 2021 Train Collection",
  "The Italy Collection",
  "CS:GO Weapon Case 2",
  "Shadow Case",
  "Chroma 3 Case",
  "Anubis Collection Package",
  "The Lake Collection",
  "Gamma Case",
  "Operation Vanguard Weapon Case",
  "The Baggage Collection",
  "The Chop Shop Collection",
  "The Assault Collection",
  "Operation Riptide Case",
  "The Canals Collection",
  "Falchion Case",
  "The Bank Collection",
  "The Overpass Collection",
  "The Train Collection",
  "Snakebite Case",
  "The Inferno Collection",
  "The Mirage Collection",
  "The Vertigo Collection",
  "Recoil Case",
  "Danger Zone Case",
  "Prisma 2 Case",
  "Chroma 2 Case",
  "Horizon Case",
  "The Dust 2 Collection",
  "Operation Phoenix Weapon Case",
  "Clutch Case",
  "Huntsman Weapon Case",
  "The Cobblestone Collection",
  "The Control Collection",
  "The Blacksite Collection",
  "The 2021 Mirage Collection",
  "The Dust Collection",
  "Prisma Case",
  "Kilowatt Case",
];

export const ALL_STATTRAK_COLLECTIONS = [
  "The Cobblestone Collection",
  "The 2021 Train Collection",
  "The Norse Collection",
  "The Chop Shop Collection",
  "The Overpass Collection",
  "The Bank Collection",
  "The 2021 Mirage Collection",
  "The Ancient Collection",
  "The 2021 Vertigo Collection",
  "The Control Collection",
  "The Canals Collection",
  "The Baggage Collection",
  "The Rising Sun Collection",
  "The Dust Collection",
  "The Havoc Collection",
  "The Train Collection",
  "The Lake Collection",
  "The 2018 Inferno Collection",
  "The Vertigo Collection",
  "The Inferno Collection",
  "The Italy Collection",
  "The Gods and Monsters Collection",
  "The Safehouse Collection",
  "The St. Marc Collection",
  "The 2021 Dust 2 Collection",
  "The Cache Collection",
  "The Assault Collection",
  "The 2018 Nuke Collection",
  "The Alpha Collection",
  "The Aztec Collection",
  "Anubis Collection Package",
  "The Dust Collection",
  "The Mirage Collection",
  "The Office Collection",
  "The Militia Collection",
  "The Nuke Collection",
  "The Blacksite Collection",
];

export const ALL_FAQS = [
  {
    Q: "Q: How does TradeUpFinder calculate trade-ups?",
    A: "A: Our platform uses advanced algorithms and real-time market data to identify the most profitable trade-up opportunities for the conditions specified.",
  },
  {
    Q: "Q: What is the difference between the free and pro plans?",
    A: "A: The free plan offers basic features such as a trade-up calculator, while the Pro plan provides advanced tools, AI-powered insights, and detailed trade-up analytics.",
  },
  {
    Q: "Q: How often is the market data updated?",
    A: "A: Market data is updated in real-time to ensure you always have the most accurate information. Our data comes from several sources in order to protect our users from manipulation and misleading data.",
  },
  {
    Q: "Q: How can I track my trade history?",
    A: 'A: When you find a trade-up you like, you can save them and view the trade-ups in your "Profile" page.',
  },
  {
    Q: "Q: What payment methods do you accept?",
    A: "A: We accept all major credit cards and PayPal.",
  },
];
