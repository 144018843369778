import React, { useState, useEffect } from "react";
import { ButtonGroup, Button } from "react-bootstrap";

import { CONDITION_SCALE_RANGES } from "../utils/constants.utils";
import {
  calculateLinePosition,
  ConditionLabels,
} from "../utils/ConditionScale.utils";

import "../styles/conditionScale.scss";

function ConditionScale({ floatVal, skinFloatValues, updateCondition }) {
  const { maxFloatValue, minFloatValue } = skinFloatValues;

  // adjust skin values for display
  const fixedFloatVal = (Math.trunc(floatVal * 1000) / 1000).toFixed(3); //float val beneath scale, truncate at 3rd decimal without rounding

  const [currentCondition, setCurrentCondition] = useState("");
  const [floatValPosition, setFloatValPosition] = useState("");

  useEffect(() => {
    // set initial condition based on the float value
    for (const [condition, floatBounds] of Object.entries(
      CONDITION_SCALE_RANGES
    )) {
      const min = floatBounds.min,
        max = floatBounds.max;
      if (floatVal >= min && floatVal <= max) {
        setCurrentCondition(condition);
        break;
      }
    }

    const initialFloatValPosition = calculateLinePosition(floatVal);
    setFloatValPosition(initialFloatValPosition);
  }, [floatVal]);

  const updateCurrentCondition = (selectedCondition) => {
    // when a condition is clicked on, set the current float val as the mean point of that condition
    // and highlight that condition if necessary

    const { min, max } = CONDITION_SCALE_RANGES[selectedCondition];
    const newFloatVal = parseFloat((max + min) / 2).toFixed(5);

    if (selectedCondition !== currentCondition) {
      if (newFloatVal > minFloatValue && newFloatVal < maxFloatValue) {
        setCurrentCondition(selectedCondition);
      }
    }
    if (newFloatVal !== floatVal) {
      updateCondition(newFloatVal);
    }
  };

  return (
    <div className="conditionScaleCont">
      <div className="scaleConditions">
        <div className="rangeText">
          <div
            className="minMaxlabelStyle maxLabelColor"
            style={{ left: calculateLinePosition(minFloatValue) }}
          >
            min
          </div>
          <div
            className="minMaxlabelStyle minLabel"
            style={{ left: calculateLinePosition(maxFloatValue) }}
          >
            max
          </div>
        </div>
        <ButtonGroup className="scaleConditionsBtnGroup">
          <Button
            className={`scaleSection ${
              currentCondition === "FN" ? "selectedConditionBg" : ""
            }`}
            onClick={() => updateCurrentCondition("FN")}
          >
            FN
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "MW" ? "selectedConditionBg" : ""
            }`}
            onClick={() => updateCurrentCondition("MW")}
          >
            MW
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "FT" ? "selectedConditionBg" : ""
            }`}
            onClick={() => updateCurrentCondition("FT")}
          >
            FT
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "WW" ? "selectedConditionBg" : ""
            }`}
            onClick={() => updateCurrentCondition("WW")}
          >
            WW
          </Button>
          {/* place lines before final button to ensure button group border radius is properly applied */}

          <div
            className="conditionScaleLine minLine"
            style={{ left: calculateLinePosition(minFloatValue) }}
          ></div>
          <div
            className="conditionScaleLine currentValLine"
            style={{ left: floatValPosition }}
          ></div>
          <div
            className="conditionScaleLine maxLine"
            style={{ left: calculateLinePosition(maxFloatValue) }}
          ></div>
          <Button
            className={`scaleSection ${
              currentCondition === "BS" ? "selectedConditionBg" : ""
            }`}
            onClick={() => updateCurrentCondition("BS")}
          >
            BS
          </Button>
        </ButtonGroup>

        <div className="rangeText">
          <div className="valueLabels">
            <span
              className="valueLabelStyle maxLabelColor"
              style={{ left: calculateLinePosition(minFloatValue) }}
            >
              <span>{minFloatValue}</span>
            </span>
            <span
              className="valueLabelStyle minLabelColor minValueLabel"
              style={{ left: calculateLinePosition(maxFloatValue) }}
            >
              <span>{maxFloatValue}</span>
            </span>
            <span
              className="valueLabelStyle currentValLabel"
              style={{ left: floatValPosition }}
            >
              {fixedFloatVal}
            </span>
          </div>
          <ConditionLabels />
        </div>
      </div>
    </div>
  );
}

export default ConditionScale;
