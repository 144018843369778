import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isUserSubscribed } from "../utils/General.utils";

import "../styles/calculator.scss";

import SkinSelector from "../components/SkinSelector";
import OutcomeSkinList from "../components/OutcomeSkinList";

import {
  Col,
  Row,
  Button,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";

import { RARITIES } from "../utils/constants.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";

function Calculator() {
  const [allSelectedSkins, setAllSelectedSkins] = useState(
    new Array(10).fill(null)
  );
  const [isSomeSkinSelected, setIsSomeSkinSelected] = useState(false);
  const [isDisplayOutcomes, setIsDisplayOutcomes] = useState(false);
  const [outcomeSummary, setOutcomeSummary] = useState({});
  const [selectedRarity, setSelectedRarity] = useState("MS");
  const [isStatTrakSelected, setIsStatTrakSelected] = useState(false);

  const navigate = useNavigate();

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const checkIsSubbed = async () => {
      const isSubbed = await isUserSubscribed();
      setIsSubscribed(isSubbed);
    };

    checkIsSubbed();
  }, []);

  useEffect(() => {
    //check if any skins are selected in order to control the clear button display
    const isSkinSelected = allSelectedSkins.some((element) => element !== null);
    setIsSomeSkinSelected(isSkinSelected);

    // check that all skins have been properly filled before displaying outcomes
    if (allSelectedSkins.every((skin) => skin !== null)) {
      const skinDataForReqParams = JSON.stringify(
        allSelectedSkins.map((skin) => {
          const { id, collection, condition, floatVal, price } = skin;
          return {
            id,
            collection,
            condition,
            floatVal,
            price,
            rarity: RARITIES[selectedRarity],
          };
        })
      );

      axios
        .post(`${process.env.REACT_APP_API_URL}/calculatorOutcomes`, {
          skinDataForReqParams,
        })
        .then((res) => {
          setOutcomeSummary(res.data);
          setIsDisplayOutcomes(true);
        })
        .catch((e) =>
          console.log("Error fetching outcome skins for calculator", e)
        );
    } else {
      setIsDisplayOutcomes(false);
    }
  }, [allSelectedSkins]);

  const updateSelectedRarity = (eventKey) => {
    Object.keys(RARITIES).forEach((rarity) => {
      if (eventKey === rarity) {
        setSelectedRarity(eventKey);
        return;
      }
    });
  };

  const removeSelectedSkin = (skinSelectorIdx) => {
    const skinsToUpdate = [...allSelectedSkins];
    skinsToUpdate[skinSelectorIdx] = null;
    setAllSelectedSkins(skinsToUpdate);
  };

  const clearAllSkins = () => {
    setAllSelectedSkins(new Array(10).fill(null));
  };

  const updateSelectedSkinArr = (skinSelectorIdx, skinDetails) => {
    const skinsToUpdate = [...allSelectedSkins];
    let updatingSkinDetails = { ...skinDetails };

    skinsToUpdate[skinSelectorIdx] = updatingSkinDetails;

    setAllSelectedSkins(skinsToUpdate);
  };

  const duplicateSelectedSkin = (skinSelectorIdx, skinDetails) => {
    const skinsToUpdate = [...allSelectedSkins];
    let idxForDuplicate = (skinSelectorIdx + 1) % 10; // Start from the next index, wrapping around if necessary

    // Find the next empty index, wrapping around if necessary
    while (skinsToUpdate[idxForDuplicate] !== null) {
      idxForDuplicate = (idxForDuplicate + 1) % 10;
      if (idxForDuplicate === skinSelectorIdx) {
        return;
      }
    }
    skinsToUpdate[idxForDuplicate] = { ...skinDetails };

    setAllSelectedSkins(skinsToUpdate);
  };

  const renderSkinSelectors = () => {
    return allSelectedSkins.map((skin, i) => (
      <div className="selectorCont" key={i}>
        <SkinSelector
          key={i}
          selectedSkin={skin}
          skinSelectorIdx={i}
          currentRarity={RARITIES[selectedRarity]}
          isStatTrak={isStatTrakSelected}
          updateSelectedSkin={updateSelectedSkinArr}
          removeSelectedSkin={removeSelectedSkin}
          duplicateSelectedSkin={duplicateSelectedSkin}
        />
      </div>
    ));
  };

  return (
    <div className="calculatorCont">
      <Row className="calcSelectionCont" md={12}>
        <Col className="descColumnTitleCont" md="12" sm="12">
          <h1>Trade-Up Contract Calculator</h1>
          <p className="calculatorSelectorSubHeader">
            Use this calculator to simulate the potential odds and exact float
            values of the output skins of a trade-up contract. Select 10 input
            skins on the right to see results instantly.
          </p>
          <div className="rarityFilterToggles">
            <div className="raritySelectorCont">
              <div className="raritySelectorLabel">Select a rarity</div>
              <DropdownButton
                as={ButtonGroup}
                onSelect={(eventKey) => {
                  updateSelectedRarity(eventKey);
                }}
                variant="outline-primary"
                title={RARITIES[selectedRarity]}
                className="rarityDropdownCont"
              >
                <Dropdown.Item
                  eventKey="CG"
                  id="dropdownItemCG"
                  active={selectedRarity === "CG"}
                >
                  Consumer Grade
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="IG"
                  id="dropdownItemIG"
                  active={selectedRarity === "IG"}
                >
                  Industrial Grade
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="MS"
                  id="dropdownItemMS"
                  active={selectedRarity === "MS"}
                >
                  Mil-Spec
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="R"
                  id="dropdownItemR"
                  active={selectedRarity === "R"}
                >
                  Restricted
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="C"
                  id="dropdownItemC"
                  active={selectedRarity === "C"}
                >
                  Classified
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <Button
              onClick={() => {
                setIsStatTrakSelected((prevState) => !prevState);
              }}
              variant="outline-primary"
              className={`statTrakBtn ${
                isStatTrakSelected ? "statTrakBtnActive" : ""
              }`}
            >
              Stat Trak™
            </Button>
          </div>
        </Col>
        <Col className="calcSkinSelectionCont" md="12" sm="12">
          <div className="calcSkinSelectionContClearAllBtnCont">
            {isSomeSkinSelected ? (
              <Button variant="outline-primary" onClick={() => clearAllSkins()}>
                Clear all Skins
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="calcSkinSelectedEls">{renderSkinSelectors()}</div>
        </Col>
      </Row>
      {isDisplayOutcomes ? (
        <OutcomeSkinList outcomeSummary={outcomeSummary} />
      ) : (
        <></>
      )}
      <div className="homepageCont">
        <div className="homeDescriptionSection">
          <h1 className="homeDescTitle">Get Detailed Insights</h1>
          <div className="homeDescSteps">
            <div className="homeDescStep">
              <img
                className="homeDescIcon"
                src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Icons/value_1.png"
                alt="Total Value"
              />
              <h3>Total Value:</h3>
              <p>Calculate the total value of the weapons used</p>
            </div>
            <div className="homeDescStep">
              <FontAwesomeIcon
                className="homeDescIcon"
                icon={faPercent}
                style={{ color: " #ffffff" }}
                alt="Condition"
              />
              <h3>Probability:</h3>
              <p>See the percentage probability for each potential outcome</p>
            </div>
            <div className="homeDescStep">
              <img
                className="homeDescIcon"
                src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Icons/target.png"
                alt="Condition"
              />
              <h3>Condition:</h3>
              <p>Get the exact condition of the resulting item</p>
            </div>
          </div>
          <div className="homeDescSteps">
            <div className="homeDescStep">
              <img
                className="homeDescIcon"
                src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Icons/steam.png"
                alt="Float Value"
              />
              <h3>Float Value:</h3>
              <p>Know the exact wear value outcome</p>
            </div>
            <div className="homeDescStep">
              <img
                className="homeDescIcon"
                src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Icons/dollar.png"
                alt="Prices"
              />
              <h3>Prices:</h3>
              <p>Check the current market prices of each weapon</p>
            </div>
            <div className="homeDescStep">
              <img
                className="homeDescIcon"
                src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Icons/link.png"
                alt="Market Links"
              />
              <h3>Market Links</h3>
              <p>
                Access direct links to market and database for further analysis
              </p>
            </div>
          </div>
        </div>
        <h3>
          Automatically generate and find profitable trade ups with Pro AI
        </h3>
        <Button
          className="calcCtaBtn"
          variant="secondary"
          size="lg"
          onClick={() => navigate("/trade-up")}
        >
          {isSubscribed ? "Trade Now" : "Learn more about Pro"}
        </Button>
      </div>
    </div>
  );
}

export default Calculator;
