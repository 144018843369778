import React from "react";
import { Row, Col } from "react-bootstrap";
import OutcomesSkin from "../components/OutcomesSkin";

const OutcomeSkinList = ({ outcomeSummary }) => {
  const {
    avgFloat,
    tradeupCost,
    profitability,
    profit,
    oddsToProfit,
    outputSkins,
  } = outcomeSummary;

  const oddsToProfitPercentage = parseFloat(oddsToProfit) * 100;

  const fixedAvgFloat = avgFloat.toFixed(7);
  const fixedtradeupCost = `$${tradeupCost.toFixed(2)}`;
  const fixedProfitability = `${parseFloat(profitability).toFixed(2)}%`;
  const fixedProfit = `$${parseFloat(profit).toFixed(2)}`;
  const fixedOddsToProfit = `${oddsToProfitPercentage.toFixed(2)}%`;

  const renderOutcomeSkins = () => {
    return outputSkins.map((skin, i) => {
      return (
        <Col className="selectorCont" md={2} xs="6" key={i}>
          <OutcomesSkin key={skin.id} skinDetails={skin} />
        </Col>
      );
    });
  };

  return (
    <div className="calcOutcomesCont">
      <h1 className="calcOutcomesTitle">Outcomes</h1>
      <div className="outcomeSummaryHeader">
        <div className="outcomeSummaryHeaderItem">
          <p className="outcomeSummaryHeaderItemTitle">AVERAGE FLOAT</p>
          <p className="outcomeSummaryHeaderItemValue">{fixedAvgFloat}</p>
        </div>
        <div className="outcomeSummaryHeaderItem">
          <p className="outcomeSummaryHeaderItemTitle">TRADE UP COST</p>
          <p className="outcomeSummaryHeaderItemValue">{fixedtradeupCost}</p>
        </div>
        <div className="outcomeSummaryHeaderItem">
          <p className="outcomeSummaryHeaderItemTitle">PROFITABLITY</p>
          <p className="outcomeSummaryHeaderItemValue">{fixedProfitability}</p>
        </div>
        <div className="outcomeSummaryHeaderItem">
          <p className="outcomeSummaryHeaderItemTitle">PROFIT</p>
          <p className="outcomeSummaryHeaderItemValue">{fixedProfit}</p>
        </div>
        <div className="outcomeSummaryHeaderItem">
          <p className="outcomeSummaryHeaderItemTitle">ODDS TO PROFIT</p>
          <p className="outcomeSummaryHeaderItemValue">{fixedOddsToProfit}</p>
        </div>
      </div>
      <Row className="calcRow">{renderOutcomeSkins()}</Row>
    </div>
  );
};

export default OutcomeSkinList;
