//* Centralize static and reusable elements that are common to the dynamic and static condition scale
import { CONDITION_SCALE_RANGES } from "./constants.utils";

// set the position of the min and max value indicators based on the bounds of the scale
export const calculateLinePosition = (value) => {
  // internal variables to be used in the floatVal position calculation
  let defaultCondition = {},
    currentConditionRange = "";

  // set condition based on the float value
  for (const [condition, floatBounds] of Object.entries(
    CONDITION_SCALE_RANGES
  )) {
    const min = floatBounds.min,
      max = floatBounds.max;
    if (value >= min && value <= max) {
      currentConditionRange = condition;
      defaultCondition = { min, max };
      break;
    }
  }

  // static width of each condition button based on scale width
  const conditionScaleWidth = 140;
  const conditionWidth = conditionScaleWidth / 5;

  // get the relative position of the floatVal within its current condition
  const relativePosition =
    (value - defaultCondition.min) /
    (defaultCondition.max - defaultCondition.min);

  // get the absolute position of the floatVal within the condition container
  return `${Math.floor(
    conditionWidth *
      (Object.keys(CONDITION_SCALE_RANGES).indexOf(currentConditionRange) +
        relativePosition)
  )}px`;
};

export const ConditionLabels = () => {
  return (
    <div className="conditionLabels">
      <span className="conditionValLabel">
        {CONDITION_SCALE_RANGES["FN"].min}
      </span>
      <span className="conditionValLabel">
        {CONDITION_SCALE_RANGES["MW"].min}
      </span>
      <span className="conditionValLabel">
        {CONDITION_SCALE_RANGES["FT"].min}
      </span>
      <span className="conditionValLabel">
        {CONDITION_SCALE_RANGES["WW"].min}
      </span>
      <span className="conditionValLabel">
        {CONDITION_SCALE_RANGES["BS"].min}
      </span>
      <span className="conditionValLabel">
        {CONDITION_SCALE_RANGES["BS"].max}
      </span>
    </div>
  );
};
