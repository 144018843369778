import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { Form as RBForm, Button, Modal } from "react-bootstrap";

import ErrorToast from "../../components/General/ErrorToast";

import "../../styles/authentication.scss";
import { isUserLoggedIn } from "../../utils/General.utils";

const Login = () => {
  const navigate = useNavigate();

  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  const [isShowModal, setIsShowModal] = useState(false);
  const [isResetSubmitted, setIsResetSubmitted] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [isInvalidResetSubmission, setIsInvalidResetSubmission] =
    useState(false);

  const handleModalShow = () => setIsShowModal(true);
  const handleModalClose = () => setIsShowModal(false);

  useEffect(() => {
    if (isUserLoggedIn()) {
      navigate("/");
    }
  }, [navigate]);

  /* LOGIN FORM LOGIC */
  const loginValidationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleLogin = (values, { setSubmitting }) => {
    const lowercaseEmailVals = {
      ...values,
      email: values.email.toLowerCase(),
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/userLogin`, lowercaseEmailVals)
      .then((res) => {
        const token = res.data.token;
        localStorage.setItem("authToken", token);
        navigate("/");
      })
      .catch((e) => {
        console.log("Login failed:", e, e.message);
        setLoginErrorMessage(e.message);
        setIsShowError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  /* RESET FORM LOGIC*/
  const handleResetValChange = (e) => {
    setResetEmail(e.target.value);
    setIsInvalidResetSubmission(false);
  };

  const handleResetEmailSubmit = async (e) => {
    e.preventDefault();

    // check for input validation
    try {
      await yup.string().email("Invalid email").validate(resetEmail);
    } catch (e) {
      return setIsInvalidResetSubmission(true);
    }

    // submit validated input and continue to next step regardless of outcome
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/userPasswordReset`, {
        email: resetEmail,
      });
    } catch (e) {
    } finally {
      setIsResetSubmitted(true);
    }
  };

  const closeModalFromResetSuccess = () => {
    handleModalClose();

    //reset modal state
    setIsResetSubmitted(false);
    setIsInvalidResetSubmission(false);
  };

  return (
    <div className="authFormCont">
      <h2 className="authFormHeader">LOGIN</h2>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginValidationSchema}
        onSubmit={handleLogin}
      >
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form className="authForm">
            <div className="authInputGroup">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                aria-describedby="inputGroupPrepend"
                className={`form-control authInputField ${
                  errors.email && touched.email ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="authInputGroup">
              <label htmlFor="password">Password</label>
              <Field
                type="password"
                name="password"
                id="password"
                className={`form-control authInputField ${
                  errors.password && touched.password ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <Button
              variant="outline-primary"
              type="submit"
              className="authSubmit"
              disabled={isSubmitting || !isValid}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <div className="loginPageAddtlOptions">
        <p>
          <span
            className="loginPageRedirectLink"
            onClick={() => navigate("/signup")}
          >
            Sign up for an account
          </span>{" "}
          |{" "}
          <span
            className="loginPageRedirectLink"
            onClick={() => handleModalShow()}
          >
            Forgot password
          </span>
        </p>
      </div>
      <Modal
        className="modalCont"
        size="md"
        show={isShowModal}
        onHide={handleModalClose}
        centered
      >
        <Modal.Header closeButton closeVariant="white" />
        <Modal.Body>
          {!isResetSubmitted ? (
            <RBForm
              className="resetModalContent"
              onSubmit={handleResetEmailSubmit}
            >
              <RBForm.Group
                className="resetModalEmailSubmitGroup"
                controlId="formEmail"
              >
                <RBForm.Label>
                  Enter your email to reset your password:
                </RBForm.Label>
                <RBForm.Control
                  type="email"
                  name="email"
                  value={resetEmail}
                  onChange={handleResetValChange}
                  isInvalid={isInvalidResetSubmission}
                  className="authInputField"
                />
                <RBForm.Control.Feedback type="invalid">
                  Invalid email
                </RBForm.Control.Feedback>
              </RBForm.Group>
              <Button
                variant="outline-primary"
                className="resetModalEmailBtn"
                type="submit"
              >
                Reset password
              </Button>
            </RBForm>
          ) : (
            <div className="resetModalContent">
              <p>
                Your password reset request has been successfully submitted.
                Please check your email for the next steps.
              </p>
              <Button
                variant="outline-primary"
                className="resetModalEmailBtn"
                onClick={() => {
                  closeModalFromResetSuccess();
                  navigate("/login");
                }}
              >
                Back to login
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <ErrorToast
        errorMessage={loginErrorMessage}
        isOpen={isShowError}
        closeToast={() => setIsShowError(false)}
      />
    </div>
  );
};

export default Login;
