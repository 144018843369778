import React, { useState, useEffect } from "react";
import axios from "axios";
import { isUserLoggedIn } from "../utils/General.utils";
import { useNavigate } from "react-router";

import {
  Accordion,
  Modal,
  Button,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import TradeSummary from "../components/TradeSummary";
import ErrorToast from "../components/General/ErrorToast";

import "../styles/profile.scss";
import "../styles/General/toasts.scss";

const UserProfile = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [userTrades, setUserTrades] = useState([]);

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isShowCancellationToast, setIsShowCancellationToast] = useState(false);
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate("/");
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/userProfileInfo`)
        .then((res) => {
          setUserDetails(res.data);
          setUserTrades(res.data.saved_trades);
          setIsLoading(false);
        })
        .catch((e) => console.log("Error fetching user profile deatils", e));
    }
  }, [navigate]);

  const cancelSub = () => {
    setIsShowConfirmationModal(false);
    axios
      .post(`${process.env.REACT_APP_API_URL}/cancel_subscription`)
      .then(() => setIsShowCancellationToast(true))
      .catch(() => setIsShowErrorToast(true));
  };

  const {
    subscription: isSubscribed,
    subscription_end_date: renewalDate,
    member_since: memberSince,
    totalTrades,
  } = userDetails;

  const deleteTradeFromList = (tradeId) => {
    const filteredTrades = userTrades.filter((trade) => {
      if (trade.id === tradeId) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/removeGeneratedTradeData`, {
            id: trade.id,
          })
          .then(() => console.log("Trade successfully deleted"))
          .catch((e) => console.log("Error removing trade in db", e));
      }
      return trade.id !== tradeId;
    });

    setUserTrades(filteredTrades);
  };

  const renderSavedTrades = () => {
    if (userTrades?.length === 0) {
      return <h3>No saved trades</h3>;
    }

    const savedTradeComps = userTrades.map((trade) => {
      // console.log(typeof trade.inputSkins);
      console.log(trade);
      return (
        <TradeSummary
          trade={trade}
          isShowDelete={true}
          deleteTradeFromList={deleteTradeFromList}
          key={trade.id}
        />
      );
    });

    return (
      <Accordion className="tuAccordionWrapper profileCollectionAccordion">
        {savedTradeComps}
      </Accordion>
    );
  };

  return (
    <div className="userProfileCont">
      <h1>My Profile</h1>
      <div className="userProfileSummary">
        <div className="userProfileSavedTrades">
          <h3 className="userProfileSavedTradesHeader">Saved Trade ups:</h3>
          {isLoading ? <>Loading...</> : renderSavedTrades()}
        </div>
        <div className="userProfileSummarySection">
          <div className="userProfileSummaryCol">
            <h3>Profile details</h3>

            <p>Member since: {isSubscribed ? memberSince : "N/A"}</p>
            <p>Subcription renews on: {isSubscribed ? renewalDate : "N/A"}</p>
            {isSubscribed && (
              <p
                className="cancelSubBtn"
                onClick={() => setIsShowConfirmationModal(true)}
              >
                Cancel my subscription
              </p>
            )}
          </div>
          <div className="userProfileSummaryCol">
            <h3>Trade details</h3>
            <p>Trade ups completed: {totalTrades}</p>
          </div>
        </div>
      </div>
      <Modal
        className="modalCont profileModalCont"
        show={isShowConfirmationModal}
        onHide={() => setIsShowConfirmationModal(false)}
        size="md"
        centered
      >
        <Modal.Header closeButton closeVariant="white"></Modal.Header>
        <Modal.Body>
          <h2>Are you sure you want to cancel your subscription?</h2>
          <Button
            variant="outline-primary"
            className="profileModalBtn"
            size="lg"
            onClick={() => cancelSub()}
          >
            Cancel Subscription
          </Button>
        </Modal.Body>
      </Modal>
      <ToastContainer position="bottom-end" className="primaryToastCont">
        <Toast
          onClose={() => setIsShowCancellationToast(false)}
          show={isShowCancellationToast}
          delay={3000}
          autohide
          bg="primary"
        >
          <Toast.Header closeVariant="white">
            <strong>Error</strong>
          </Toast.Header>
          <Toast.Body>
            <p>Your subscription is cancelled.,k1 1</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ErrorToast
        errorMessage="Server error cancelling your subscription. Please contact us."
        isOpen={isShowErrorToast}
        closeToast={() => setIsShowErrorToast(false)}
      />
    </div>
  );
};

export default UserProfile;
