import React, { useState, useEffect } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import StaticConditionScale from "./StaticConditionScale";

import "../styles/skinComponents.scss";

function OutcomesSkin({ skinDetails, isHasFloat = true }) {
  const {
    image_url,
    name,
    collection,
    floatVal,
    price,
    probability,
    float_range_min,
    float_range_max,
  } = skinDetails;
  const skinFloatValues = {
    minFloatValue: float_range_min,
    maxFloatValue: float_range_max,
  };

  const [isShowProbability, setIsShowProbability] = useState(false);
  useEffect(() => {
    if (probability !== undefined) {
      setIsShowProbability(true);
    }
  }, [probability]);

  const fixedFloatVal = parseFloat(floatVal).toFixed(5);
  const probabilityPercentage = parseFloat(probability) * 100;
  const fixedProfitability = probabilityPercentage.toFixed(2);

  return (
    <div className="skinCont skinImgCard outcomeSkin">
      <div className="skinCardHeader">
        <OverlayTrigger overlay={<Tooltip>{collection}</Tooltip>}>
          <p className="skinCardHeaderCollection">{collection}</p>
        </OverlayTrigger>
        {isHasFloat && (
          <p className="skinCardSaticFloatDisplay">{fixedFloatVal}</p>
        )}
      </div>
      <img className="skinCardImg" src={image_url} alt={name} />
      <p className="skinCardTitle">{name}</p>
      <div className="skinCardConditionScale">
        <StaticConditionScale
          floatVal={floatVal}
          skinFloatValues={skinFloatValues}
          isHasFloat={isHasFloat}
        />
      </div>
      <div className="skinSelectedValues">
        {isHasFloat && (price === "0" ? "No recent price" : `$${price}`)}
        {isHasFloat && isShowProbability && "-"}
        {isShowProbability && `${fixedProfitability}%`}
      </div>
    </div>
  );
}

export default OutcomesSkin;
