import React, { useState } from "react";
import { Card, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { isUserLoggedIn } from "../utils/General.utils";

import "../styles/subscribe.scss";

const Subscribe = () => {
  const [activePlan, setActivePlan] = useState("3mo");
  const [isShowSignupModal, setIsShowSignupModal] = useState(false);
  const navigate = useNavigate();

  const handleActivePlanChange = (selectedPlan) => {
    if (selectedPlan !== activePlan) {
      setActivePlan(selectedPlan);
    }
  };

  const handlePaymentRedirect = () => {
    if (!isUserLoggedIn()) {
      return setIsShowSignupModal(true);
    }
    navigate(`/checkout/${activePlan}`);
  };

  return (
    <div className="subscriptionCont">
      <div className="subContentWrapper">
        <h1 className="subHeader">Subscription Plans</h1>
        <h3 className="subSubheader">
          Start finding profitable trade ups immediately. Up to 10 trade ups
          within the first hour of subscription
        </h3>
        <div className="subPlans">
          <Card
            className={`subPlan ${activePlan === "1mo" ? "activePlan" : ""}`}
            onClick={() => handleActivePlanChange("1mo")}
          >
            <Card.Img
              variant="top"
              src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/subscribe_page/1month_highlight.png"
            />
            <Card.Header as="h3">1 Month</Card.Header>
            <Card.Body>
              <h1>$18.99/mo</h1>
              <Button
                className="subPlanCta"
                variant={activePlan === "1mo" ? "secondary" : "outline-light"}
              >
                {activePlan === "1mo" ? "Selected" : "Select"}
              </Button>
            </Card.Body>
          </Card>
          <Card
            className={`subPlan ${activePlan === "3mo" ? "activePlan" : ""}`}
            onClick={() => handleActivePlanChange("3mo")}
          >
            <Card.Img
              variant="top"
              src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/subscribe_page/3months_highlight.png"
            />
            <Card.Header as="h3">3 Months</Card.Header>
            <Card.Body>
              <div className="subPopularPlan">
                <h1>$16.99/mo</h1>
                <p className="subPopularPlanTag">Popular</p>
              </div>
              <Button
                className="subPlanCta"
                variant={activePlan === "3mo" ? "secondary" : "outline-light"}
              >
                {activePlan === "3mo" ? "Selected" : "Select"}
              </Button>
            </Card.Body>
          </Card>
          <Card
            className={`subPlan ${activePlan === "1yr" ? "activePlan" : ""}`}
            onClick={() => handleActivePlanChange("1yr")}
          >
            <Card.Img
              variant="top"
              src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/subscribe_page/1year_highlight.png"
            />
            <Card.Header as="h3">
              1 Year <span className="subSpecialOfferText">+ 3mo free</span>
            </Card.Header>
            <Card.Body>
              <h1>$13.99/mo</h1>
              <Button
                className="subPlanCta"
                variant={activePlan === "1yr" ? "secondary" : "outline-light"}
              >
                {activePlan === "1yr" ? "Selected" : "Select"}
              </Button>
            </Card.Body>
          </Card>
        </div>
        <h3 className="subSubheader">
          Special Deal: 12 months + 3 months Free. Unlimited amount of trade ups
        </h3>
        <Button
          className="subCta"
          variant="secondary"
          size="lg"
          onClick={() => handlePaymentRedirect()}
        >
          Subscribe now
        </Button>
      </div>
      <Modal
        className="modalCont subModalCont"
        show={isShowSignupModal}
        onHide={() => setIsShowSignupModal(false)}
        size="md"
        centered
      >
        <Modal.Header closeButton closeVariant="white"></Modal.Header>
        <Modal.Body>
          <h2>Create an account or log in to subscribe</h2>
          <Button
            variant="secondary"
            className="subModalBtn"
            size="lg"
            onClick={() => navigate("/signup")}
          >
            Signup here
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Subscribe;
