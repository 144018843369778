import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import {
  Button,
  Col,
  Row,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

import TradeSummary from "../../components/TradeSummary";
import CollectionDropdown from "../../components/CollectionDropdown";

import { RARITIES } from "../../utils/constants.utils";

import "../../styles/tradeUp.scss";

const Tradeup = () => {
  const defaultCollectionState = "Select collection";

  const [selectedCollection1, setSelectedCollection1] = useState(
    defaultCollectionState
  );
  const [selectedCollection2, setSelectedCollection2] = useState(
    defaultCollectionState
  );

  const [expectedAvgFloat, setExpectedAvgFloat] = useState(0.068);
  const [expectedMinFloat, setExpectedMinFloat] = useState(0.0035);

  const [isSecondCollectionDisabled, setIsSecondCollectionDisabled] =
    useState(true);
  const [collectionSkinAmounts, setCollectionSkinAmounts] = useState({
    c1Amount: 9,
    c2Amount: 1,
  });
  const [isDisplayTradeGenerator, setIsDisplayTradeGenerator] = useState(false);

  const [selectedRarity, setSelectedRarity] = useState("MS");
  const [isStatTrak, setisStatTrak] = useState(false);

  const [allTrades, setAllTrades] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCollection1 !== defaultCollectionState) {
      setIsSecondCollectionDisabled(false);
      setIsDisplayTradeGenerator(true);
    } else {
      setIsDisplayTradeGenerator(false);
    }
  }, [selectedCollection1]);

  useEffect(() => {
    // Reset trade details in case of rarity change
    setSelectedCollection1(defaultCollectionState);
    setSelectedCollection2(defaultCollectionState);
    setIsSecondCollectionDisabled(true);
    setExpectedAvgFloat(0.068);
    setExpectedMinFloat(0.0035);
    setAllTrades([]);
  }, [selectedRarity, isStatTrak]);

  const updateSelectedRarity = (eventKey) => {
    Object.keys(RARITIES).forEach((rarity) => {
      if (eventKey === rarity) {
        setSelectedRarity(eventKey);
        return;
      }
    });
  };

  const generateTradesFromCollections = () => {
    const selectedCollections = {};

    if (selectedCollection1 === defaultCollectionState) {
      selectedCollections.selectedCollection1 = "";
    } else {
      selectedCollections.selectedCollection1 = selectedCollection1;
    }

    if (selectedCollection2 === defaultCollectionState) {
      selectedCollections.selectedCollection2 = "";
    } else {
      selectedCollections.selectedCollection2 = selectedCollection2;
    }

    const collectionParams = JSON.stringify({
      ...selectedCollections,
      collection1_count: collectionSkinAmounts.c1Amount,
      collection2_count: collectionSkinAmounts.c2Amount,
      avg_float_expected: expectedAvgFloat,
      expected_min_float: expectedMinFloat,
      rarity: RARITIES[selectedRarity],
      isStatTrak,
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/tradesByCollection`, {
        collectionParams,
      })
      .then((res) => {
        const newTradeData = res.data[0];
        setAllTrades([newTradeData, ...allTrades]);
      })
      .catch((e) => console.log("Error fetching trades from collections", e));
  };

  const updateCollectionOutputAmounts = (collectionNum, userValue) => {
    if (userValue > 10 || userValue < 0) {
      return console.log("Invalid input, number must be between 0 and 10");
    }

    let secondaryVal = 0;
    switch (collectionNum) {
      case 1:
        secondaryVal = 10 - userValue;
        setCollectionSkinAmounts((prevState) => ({
          ...prevState,
          c1Amount: userValue,
          c2Amount: secondaryVal,
        }));
        break;
      case 2:
        secondaryVal = 10 - userValue;
        setCollectionSkinAmounts((prevState) => ({
          ...prevState,
          c1Amount: secondaryVal,
          c2Amount: userValue,
        }));
        break;
      default:
        console.log("Error setting collection amounts");
        break;
    }
  };

  const updateMinExpectedFloat = (userMinFloat) => {
    if (userMinFloat <= expectedAvgFloat) {
      setExpectedMinFloat(userMinFloat);
    }
  };

  const handleCollection2Update = (eventKey) => {
    if (eventKey === "Clear")
      return setSelectedCollection2(defaultCollectionState);
    setSelectedCollection2(eventKey);
  };

  const deleteTradeFromList = (tradeId) => {
    const filteredTrades = allTrades.filter((trade) => {
      return trade.id !== tradeId;
    });

    setAllTrades(filteredTrades);
  };

  const renderAllTrades = () => {
    return allTrades.map((trade) => {
      return (
        <TradeSummary
          trade={trade}
          isShowDelete={true}
          deleteTradeFromList={deleteTradeFromList}
          key={trade.id}
        />
      );
    });
  };

  return (
    <div className="tradeUpCont">
      <Row className="tradeSelectionCont" md="12">
        <Col className="descColumnTitleCont" md="4" sm="12">
          <h1>Trade up</h1>
          <p>
            Automatically get odds to discover CS:GO profitable trade ups. Save
            your favourite trade ups to study all their stats later and get the
            most out of them. Once the trade up is calculated, you can save and
            manage it from our tracking page.
          </p>
          <div className="rarityFilterToggles">
            <div className="raritySelectorCont">
              <div className="raritySelectorLabel">Select a rarity</div>
              <DropdownButton
                as={ButtonGroup}
                onSelect={(eventKey) => {
                  updateSelectedRarity(eventKey);
                }}
                variant="outline-primary"
                title={RARITIES[selectedRarity]}
                className="rarityDropdownCont"
              >
                {isStatTrak ? null : (
                  <Dropdown.Item
                    eventKey="CG"
                    id="dropdownItemCG"
                    active={selectedRarity === "CG"}
                  >
                    Consumer Grade
                  </Dropdown.Item>
                )}
                {isStatTrak ? null : (
                  <Dropdown.Item
                    eventKey="IG"
                    id="dropdownItemIG"
                    active={selectedRarity === "IG"}
                  >
                    Industrial Grade
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  eventKey="MS"
                  id="dropdownItemMS"
                  active={selectedRarity === "MS"}
                >
                  Mil-Spec
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="R"
                  id="dropdownItemR"
                  active={selectedRarity === "R"}
                >
                  Restricted
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="C"
                  id="dropdownItemC"
                  active={selectedRarity === "C"}
                >
                  Classified
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <Button
              onClick={() => {
                setisStatTrak((prevState) => !prevState);
              }}
              variant="outline-primary"
              className={`statTrakBtn ${isStatTrak ? "statTrakBtnActive" : ""}`}
            >
              Stat Trak™
            </Button>
          </div>
          <div className="savedTradeBtnCont">
            <Button
              className="savedTradesBtn"
              variant="outline-primary"
              onClick={() => navigate("/profile")}
            >
              My saved Trades
            </Button>
            <div className="savedTradeBtnSpacing"></div>
          </div>
        </Col>
        <Col className="tradeSummariesColumnCont" md="8">
          <div className="tradeCollectionSelectors">
            <div className="tradeCollectionSelectorCont">
              <h3 className="tradeDropdownSelectorsLabel">
                Select one or two collections
              </h3>
              <div className="tradeCollectionSelectorDropdowns">
                <CollectionDropdown
                  selectedCollection={selectedCollection1}
                  updateSelectedCollection={setSelectedCollection1}
                  isStatTrak={isStatTrak}
                  className="collectionsDropdownCont"
                />
                <CollectionDropdown
                  selectedCollection={selectedCollection2}
                  updateSelectedCollection={handleCollection2Update}
                  isStatTrak={isStatTrak}
                  isDisabled={isSecondCollectionDisabled}
                  isShowReset={true}
                  className="collectionsDropdownCont"
                />
                {isDisplayTradeGenerator ? (
                  <Button
                    variant="secondary"
                    className="tradeCollectionSelectionBtn"
                    onClick={() => generateTradesFromCollections()}
                  >
                    Generate trades
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="tradeCollectionsAddtlFilters">
              <div className="tradeCollectionsAddtlFiltersCol tradeCollectionFloatAdjustments">
                <p className="tradeDropdownSectionLabel">
                  Set the expected float amounts
                </p>
                <div className="tradeCollectionAmountBreakdown">
                  <div className="tradeCollectionAmount">
                    <label
                      className="tradeCollectionAmountLabel"
                      htmlFor="avgFloatExp"
                    >
                      Average float exp{" "}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            Average float of the combined skins within the
                            trade-up contract.
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon icon={faCircleQuestion} />
                      </OverlayTrigger>
                    </label>
                    <input
                      id="avgFloatExp"
                      type="text"
                      className="tradeCollectionAmountInput"
                      placeholder="Search collections"
                      value={expectedAvgFloat}
                      onChange={(e) => setExpectedAvgFloat(e.target.value)}
                    />
                  </div>
                  <div className="tradeCollectionAmount">
                    <label
                      className="tradeCollectionAmountLabel"
                      htmlFor="avgMinExp"
                    >
                      Min float exp
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            The minimum float of a skin within the trade-up
                            contract.
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon icon={faCircleQuestion} />
                      </OverlayTrigger>
                    </label>
                    <input
                      id="avgMinExp"
                      type="text"
                      className="tradeCollectionAmountInput"
                      placeholder="Search collections"
                      value={expectedMinFloat}
                      onChange={(e) => updateMinExpectedFloat(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {selectedCollection2 === defaultCollectionState ? (
                <></>
              ) : (
                <div className="tradeCollectionsAddtlFiltersCol tradeCollectionSkinNumbers">
                  <p className="tradeDropdownSectionLabel">
                    Set the skin output breakdown
                  </p>
                  <div className="tradeCollectionAmountBreakdown">
                    <div className="tradeCollectionAmount">
                      <label
                        className="tradeCollectionAmountLabel"
                        htmlFor="collection1Amount"
                      >
                        Collection 1
                      </label>
                      <input
                        id="collection1Amount"
                        type="number"
                        className="tradeCollectionAmountInput"
                        placeholder="Search collections"
                        value={collectionSkinAmounts.c1Amount}
                        min={0}
                        max={10}
                        onChange={(e) =>
                          updateCollectionOutputAmounts(1, e.target.value)
                        }
                      />
                    </div>
                    <div className="tradeCollectionAmount">
                      <label
                        className="tradeCollectionAmountLabel"
                        htmlFor="collection2Amount"
                      >
                        Collection 2
                      </label>
                      <input
                        id="collection2Amount"
                        type="number"
                        className="tradeCollectionAmountInput"
                        placeholder="Search collections"
                        value={collectionSkinAmounts.c2Amount}
                        min={0}
                        max={10}
                        onChange={(e) =>
                          updateCollectionOutputAmounts(2, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Accordion className="tuAccordionWrapper tradeCollectionAccordion">
            {renderAllTrades()}
          </Accordion>
        </Col>
      </Row>
    </div>
  );
};

export default Tradeup;
