import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Spinner } from "react-bootstrap";

import OutcomesSkin from "../OutcomesSkin";

import "../../styles/selectionModal.scss";
import CollectionDropdown from "../CollectionDropdown";

const SkinSelectionModal = ({
  currentRarity,
  isStatTrak,
  handleSkinFocused,
}) => {
  const [allSkins, setAllSkins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCollection, setSelectedCollection] =
    useState("All collections");
  const [skinSearchTerm, setSkinSearchTerm] = useState("");

  useEffect(() => {
    //get list of all skins that match the filters
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/skinsByCollection?rarity=${currentRarity}&isStatTrak=${isStatTrak}&collection=${selectedCollection}`
      )
      .then((res) => {
        setAllSkins(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("error fetching skins by calculator filters", e);
      });
  }, [currentRarity, isStatTrak, selectedCollection]);

  const searchSkins = () => {
    if (skinSearchTerm !== "") {
      //get list of all skins that match the search term
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/skinsBySearch?rarity=${currentRarity}&isStatTrak=${isStatTrak}&searchTerm=${skinSearchTerm}`
        )
        .then((res) => {
          setAllSkins(res.data);
        })
        .catch((e) => {
          console.log("error fetching skins by calculator search term", e);
        });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && skinSearchTerm !== "") {
        searchSkins(skinSearchTerm);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [skinSearchTerm]);

  const updateSelectedCollection = (collection) => {
    setSelectedCollection(collection);
  };

  const renderAllSkinsList = () => {
    return allSkins.map((skinDetails) => {
      return (
        <div
          className="modalSkinInListCont"
          key={skinDetails.id}
          onClick={() => {
            handleSkinFocused(skinDetails);
          }}
        >
          <OutcomesSkin skinDetails={skinDetails} />
        </div>
      );
    });
  };

  return (
    <>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title className="modalTitleWithFilter">
          <div className="modalTitle">Select a skin for the calculator:</div>
          <div className="modalTitleDetails">
            <div className="modalTitleRarity">{currentRarity}</div>
            <CollectionDropdown
              selectedCollection={selectedCollection}
              updateSelectedCollection={updateSelectedCollection}
            />
          </div>
          <div className="modalTitleSearch">
            <input
              className="modalTitleSearchInput"
              value={skinSearchTerm}
              onChange={(e) => setSkinSearchTerm(e.target.value)}
            />
            <Button
              className="modalTitleSearchBtn"
              variant="outline-primary"
              onClick={() => searchSkins()}
            >
              Search
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="loadingCont">
            <Spinner animation="border" />
          </div>
        ) : (
          <div className="skinSelectionCont">{renderAllSkinsList()}</div>
        )}
      </Modal.Body>
    </>
  );
};

export default SkinSelectionModal;
