import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { Button } from "react-bootstrap";

import { isUserLoggedIn } from "../../utils/General.utils";
import ErrorToast from "../../components/General/ErrorToast";

import "../../styles/authentication.scss";

const Signup = () => {
  const navigate = useNavigate();

  const [signupErrorMessage, setSignupErrorMessage] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  useEffect(() => {
    if (isUserLoggedIn()) {
      navigate("/");
    }
  }, [navigate]);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/^(?=.*\d)/, "Password must contain at least one number"),
  });

  const handleSignup = (values, { setSubmitting }) => {
    const lowercaseEmailVals = {
      ...values,
      email: values.email.toLowerCase(),
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/userSignup`, lowercaseEmailVals)
      .then((res) => {
        const token = res.data.token;
        localStorage.setItem("authToken", token);
        navigate("/");
      })
      .catch((e) => {
        const message = e.response.data.email[0];
        console.error("Signup failed:", e);
        setSignupErrorMessage(message);
        setIsShowError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="authFormCont">
      <h2 className="authFormHeader">SIGN UP</h2>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSignup}
      >
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form className="authForm">
            <div className="authInputGroup">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                id="email"
                name="email"
                autoComplete="email"
                aria-describedby="inputGroupPrepend"
                className={`form-control authInputField ${
                  errors.email && touched.email ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="authInputGroup">
              <label htmlFor="password">Password</label>
              <Field
                type="password"
                id="password"
                name="password"
                className={`form-control authInputField ${
                  errors.password && touched.password ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <Button
              variant="outline-primary"
              type="submit"
              className="authSubmit"
              disabled={isSubmitting || !isValid}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <div className=""></div>
      <p className="loginPageAddtlOptions">
        Already have an account?{" "}
        <span
          className="loginPageRedirectLink"
          onClick={() => navigate("/login")}
        >
          Login here
        </span>
      </p>
      <ErrorToast
        errorMessage={signupErrorMessage}
        isOpen={isShowError}
        closeToast={() => setIsShowError(false)}
      />
    </div>
  );
};

export default Signup;
