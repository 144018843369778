import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import ErrorToast from "../../components/General/ErrorToast";

import "../../styles/authentication.scss";

const ResetPW = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [errorMessage, setErrorMessage] = useState("");
  const [isShowError, setIsShowError] = useState(false);

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/^(?=.*\d)/, "Password must contain at least one number"),
    confirmPW: yup
      .string()
      .required("Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const submitPWReset = (values, { setSubmitting }) => {
    if (values.password !== values.confirmPW) {
      setErrorMessage("Passwords do not match");
      setIsShowError(true);
      return setSubmitting(false);
    }

    const pathParts = pathname.split("/");
    const uidb64 = pathParts[2];
    const token = pathParts[3];

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/password_reset_confirm/`,
        {
          uidb64: uidb64,
          token: token,
          password: values.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        navigate("/login");
      })
      .catch((e) => {
        setErrorMessage("Error submitting password reset");
        setIsShowError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="authFormCont">
      <h2 className="authFormHeader">RESET PASSWORD</h2>
      <Formik
        initialValues={{ password: "", confirmPW: "" }}
        validationSchema={validationSchema}
        onSubmit={submitPWReset}
      >
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form className="authForm">
            <div className="authInputGroup">
              <label htmlFor="password">Password</label>
              <Field
                type="password"
                id="password"
                name="password"
                className={`form-control authInputField ${
                  errors.password && touched.password ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="authInputGroup">
              <label htmlFor="confirmPW">Confirm Password</label>
              <Field
                type="password"
                id="confirmPW"
                name="confirmPW"
                className={`form-control authInputField ${
                  errors.confirmPW && touched.confirmPW ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="confirmPW"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <Button
              variant="outline-primary"
              type="submit"
              className="authSubmit"
              disabled={isSubmitting || !isValid}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <ErrorToast
        errorMessage={errorMessage}
        isOpen={isShowError}
        closeToast={() => {
          setErrorMessage("");
          setIsShowError(false);
        }}
      />
    </div>
  );
};

export default ResetPW;
