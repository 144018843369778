import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ALL_FAQS } from "./constants.utils";
import { Accordion } from "react-bootstrap";
import {
  faForward,
  faInfinity,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";

export const RENDER_HOWITWORKS_SECTION = () => {
  return (
    <div className="homeDescriptionSection">
      <h1 className="homeDescTitle">How It Works</h1>
      <div className="homeDescSteps">
        <div className="homeDescStep">
          <img
            className="homeDescIcon"
            src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Icons/analyze.png"
            alt="Choose collections"
          />
          <h3>Choose Collections</h3>
          <p>
            Start by selecting the collections you're interested in. Our
            platform leverages advanced algorithms to analyze and identify the
            most profitable trade-up opportunities
          </p>
        </div>
        <div className="homeDescStep">
          <img
            className="homeDescIcon"
            src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Icons/discovery.png"
            alt="Discovering Trade-Ups"
          />
          <h3>Discover Trade-Ups</h3>
          <p>
            Receive a list of the most profitable trade-up contracts based on
            real-time market data. Each suggested trade-up shows expected ROI
            clearly
          </p>
        </div>
        <div className="homeDescStep">
          <img
            className="homeDescIcon"
            src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Icons/profit.png"
            alt="TradeUp & Profit"
          />
          <h3>Trade up and Profit</h3>
          <p>
            Execute your trade-ups with confidence. Track your history and
            manage your trades efficiently from your dashboard
          </p>
        </div>
      </div>
    </div>
  );
};

export const RENDER_BENEFITS_SECTION = () => {
  return (
    <div className="homeDescriptionSection tradeUpPreviewBenefits">
      <h1 className="homeDescTitle">Benefits</h1>
      <div className="homeDescSteps">
        <div className="homeDescStep">
          <FontAwesomeIcon
            className="homeDescIcon"
            icon={faInfinity}
            size="sm"
            style={{ color: " #ffffff" }}
          />
          <h3>Unlimited Trade Ups</h3>
          <p>
            Find and execute as many trade-ups as you want. Our platform
            provides endless profitable opportunities
          </p>
        </div>
        <div className="homeDescStep">
          <FontAwesomeIcon
            className="homeDescIcon"
            icon={faSackDollar}
            style={{ color: " #ffffff" }}
          />
          <h3>Instant Profits</h3>
          <p>
            Our advanced AI and real-time market data ensure you find the most
            profitable trade-ups
          </p>
        </div>
        <div className="homeDescStep">
          <FontAwesomeIcon
            className="homeDescIcon"
            icon={faForward}
            style={{ color: " #ffffff" }}
          />
          <h3>Stay Ahead of the Market</h3>
          <p>
            With up-to-date market analysis, you'll always be ahead of trends,
            ensuring maximum profitability
          </p>
        </div>
      </div>
    </div>
  );
};

export const RENDER_FAQ_SECTION = () => {
  const renderFAQs = () =>
    ALL_FAQS.map((AQPair, idx) => {
      return (
        <Accordion.Item
          className="homeFAQItem"
          as="div"
          key={idx}
          eventKey={idx}
        >
          <Accordion.Header className="homeFAQQuestion">
            {AQPair.Q}
          </Accordion.Header>
          <Accordion.Body className="homeFAQAnswer">{AQPair.A}</Accordion.Body>
        </Accordion.Item>
      );
    });

  return (
    <div className="homeFAQSection" id="homeFAQ">
      <h1>Frequently Asked Questions</h1>
      <Accordion className="tuAccordionWrapper homeFAQAccordion">
        {renderFAQs()}
      </Accordion>
    </div>
  );
};
